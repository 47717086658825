import { URL } from "./commonapi";

export const verify = `https://mindmediareactdev.nestorhawk.com/verification/`;
export const UpdateCard = `${URL}/api/method/mindmedia.api.my_account.api.update_card_details`;
export const UserGetDetails = `${URL}/api/method/mindmedia.api.my_account.api.get_user_details`;
export const UpdateProfilePic = `${URL}/api/method/mindmedia.api.my_account.api.update_profile_pic`;
export const SendOTP = `${URL}/api/method/mindmedia.api.api.request_signup_otp`;
export const VerifyOtp = `${URL}/api/method/mindmedia.api.api.verify_signup_otp`;
export const SetNewPhoneNumber = `${URL}/api/method/mindmedia.api.my_account.api.set_new_mobile`;
export const SendNotification = `${URL}/api/method/mindmedia.api.my_account.api.save_noti_setting`;
export const ForgottPassword = `${URL}/api/method/frappe.core.doctype.user.user.reset_password`;
export const Login = `${URL}/api/method/mindmedia.api.api.log_in`;
export const VerifyEmail = `${URL}/api/method/mindmedia.api.api.send_verification_mail`;
export const getProductsBasedOnFilter = `${URL}/api/method/mindmedia.api.booking.api.get_services_products_based_on_filter`;
export const SignUp = `${URL}/api/method/mindmedia.api.api.sign_up`;
export const venueListingPage = `${URL}/api/resource/Venue`;
export const getProducts = `${URL}/api/method/mindmedia.api.booking.api.get_products_based_on_item_group`;
export const CreateOrder = `${URL}/api/method/mindmedia.api.api.create_order`;
export const MaintenanceIframe = `${URL}/api/method/mindmedia.api.booking.api.create_event_for_maintanance`;
export const UpdatePhoneNumber = `${URL}/api/method/mindmedia.api.my_account.api.set_new_mobile`;
export const ItemDetails = `${URL}/api/method/mindmedia.api.booking.api.get_product_details`;
export const BookingHistory = `${URL}/api/method/mindmedia.api.booking.api.booking_history`;
export const RequestPayment = `${URL}/api/method/mindmedia.mindmedia.doctype.reddot_payment_setting.reddot_payment_setting.request_payment_url`;
export const handlePayment = `${URL}/api/method/mindmedia.mindmedia.doctype.reddot_payment_setting.reddot_payment_setting.handle_redirect`;
export const create_si_and_payment_api = `${URL}/api/method/mindmedia.api.booking.api.create_si_and_payment_api`;
export const create_si_and_payment_api_for_iframe = `${URL}/api/method/mindmedia.api.api.create_invoice_and_payment`;
export const checkIfPaymentAlreadyDone = `${URL}/api/method/mindmedia.api.booking.api.not_create_duplicate_si`;
export const AlreadyBookedPitch = `${URL}/api/method/mindmedia.api.booking.endpoints.slot_booking.get_event`;
export const SearchActivity = `${URL}/api/method/mindmedia.api.booking.api.get_uniq_bookingfor_for_filters`;
export const SearchActivityResult = `${URL}/api/method/mindmedia.api.booking.api.get_all_venues_related_event`;
export const Holidays = `${URL}/api/method/mindmedia.api.booking.api.holidays`;
export const Invoice = `${URL}/api/method/frappe.utils.print_format.download_pdf?doctype=Sales%20Invoice&format=Standard&no_letterhead=1&letterhead=No%20Letterhead&settings=%7B%7D&_lang=en`;
export const Tax = `${URL}/api/method/mindmedia.api.booking.api.get_tax_template_based_on_venue_company`;
export const Customer = `${URL}/api/method/mindmedia.api.booking.api.get_all_customers`;
export const CreateCustomer = `${URL}/api/method/mindmedia.api.api.create_new_customer`;
export const CouponCode = `${URL}/api/method/mindmedia.api.api.get_coupon_data`;
export const GetCountryTime = `${URL}/api/method/mindmedia.api.booking.api.get_current_date_time_zone_of_country    `;

//iframe
