import React, { useState, useEffect, memo } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Defaultimage from "../../assets/images/CricketKit.jpg";

const AccessoryCard = memo(
  ({
    name,
    price,
    image,
    item_category,
    nonrental,
    per_minuts_price,
    cartItems,
    setCartItems,
  }) => {
    const [cartQuantity, setCartQuantity] = useState(0);

    useEffect(() => {
      const storedCartItems =
        JSON.parse(localStorage.getItem("cartItems")) || [];
      const cartItem = storedCartItems.find((item) => item.name === name);
      if (cartItem) {
        setCartQuantity(cartItem.quantity);
      } else {
        setCartQuantity(0);
      }
    }, [name]);

    const addItemToCart = () => {
      const updatedCartItems = [...cartItems];
      const existingItemIndex = updatedCartItems.findIndex(
        (item) => item.name === name
      );
      if (existingItemIndex !== -1) {
        updatedCartItems[existingItemIndex].quantity += 1;
      } else {
        updatedCartItems.push({
          name,
          price,
          image,
          item_category,
          per_minuts_price,
          quantity: 1,
        });
      }
      toast.success("Item Added To Cart", {
        toastId: "notificationToast",
        autoClose: 1000,
      });
      setCartItems(updatedCartItems);
      localStorage.setItem("cartItems", JSON.stringify(updatedCartItems));
      setCartQuantity(cartQuantity + 1);
    };

    const removeItemFromCart = () => {
      if (cartQuantity > 0) {
        const updatedCartItems = [...cartItems];
        const existingItemIndex = updatedCartItems.findIndex(
          (item) => item.name === name
        );
        if (existingItemIndex !== -1) {
          updatedCartItems[existingItemIndex].quantity -= 1;
          if (updatedCartItems[existingItemIndex].quantity === 0) {
            updatedCartItems.splice(existingItemIndex, 1);
          }
          setCartItems(updatedCartItems);
          localStorage.setItem("cartItems", JSON.stringify(updatedCartItems));
          setCartQuantity(cartQuantity - 1);
        }
      }
    };

    return (
      <div
        className="rounded border m-2 p-2 mb-4 flex flex-col justify-between"
        style={{ width: "100%", maxWidth: "219px", height: "auto" }}
      >
        {image.length > 10 ? (
          <Link to={`/item-detail/${item_category}/${name}`}>
            <div className="relative">
              <img
                key={image}
                className="w-full h-36 object-cover"
                src={image}
                alt={name}
                onError={(e) => {
                  e.target.src = Defaultimage;
                }}
              />
            </div>
          </Link>
        ) : (
          <div className="relative">
            <img
              key={image}
              className="w-full h-36 object-cover"
              src={image}
              alt={name}
              onError={(e) => {
                e.target.src = Defaultimage;
              }}
            />
          </div>
        )}
        <div className="px-2 py-1">
          <h2 className="font-bold text-sm mb-1">{name}</h2>
          {nonrental ? (
            <p className="text-gray-700 text-xs">$ {price}</p>
          ) : (
            <p className="text-gray-700 text-xs">$ {price}/hour</p>
          )}
        </div>
        <div className="flex justify-between items-center">
          <div className="flex items-center">
            <button
              className="text-gray-700 font-bold py-1 px-2 rounded border border-gray-600 mr-2"
              onClick={removeItemFromCart}
            >
              -
            </button>
            <span className="text-gray-700">{cartQuantity}</span>
            <button
              className="text-gray-700 font-bold py-1 px-2 rounded border border-gray-600 ml-2"
              onClick={addItemToCart}
            >
              +
            </button>
          </div>
          <div className="flex justify-center">
            <button
              className="bg-red-200 hover:bg-red-300 text-red-600 font-bold py-1 px-2 rounded border border-red-600 mr-2"
              style={{ width: "75px" }}
              onClick={addItemToCart}
            >
              ADD
            </button>
          </div>
        </div>
      </div>
    );
  }
);

export default AccessoryCard;
