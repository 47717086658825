import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../../component/common/NavbarProfile";
import BackButton from "../../assets/images/backBtn.svg";
import AccessoryCard from "./AccessoryCard"; // Import the memoized AccessoryCard component
import { getProducts } from "../../handlers/common/api";
import CartIcon from "../../component/common/Carticon";

const AccessoriesPage = () => {
  const [cartItems, setCartItems] = useState([]);
  const [venueData, setVenueData] = useState(null);
  const [rentalItems, setRentalItems] = useState([]);
  const [showAll, setShowAll] = useState(true);
  const [consumableItems, setConsumableItems] = useState([]);
  const [sp00Items, setSp00Items] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("venueData");
    if (token) {
      setVenueData(JSON.parse(token));
    }

    const items = JSON.parse(localStorage.getItem("cartItems")) || [];
    setCartItems(items);
  }, []);

  const fetchRentalDetails = async () => {
    try {
      const options = {
        method: "POST",
        body: JSON.stringify({ item_group: "Rental" }),
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await fetch(`${getProducts}`, options);
      if (!response.ok) {
        throw new Error("Failed to fetch venue details");
      }
      const data = await response.json();

      const arr = [];
      const optionList = data.data;
      for (let i = 0; i < optionList?.length; i++) {
        if (optionList[i].price !== 0) {
          arr.push({
            name: optionList[i].name,
            value: optionList[i].name,
            label: optionList[i].name,
            price: optionList[i].price,
            image: optionList[i].image,
            per_minuts_price: optionList[i].per_minuts_price,
            item_category: "Rental",
          });
        }
      }
      setRentalItems(arr);
    } catch (error) {
      console.error("Error fetching venue details:", error);
    }
  };

  const fetchConsumableDetails = async () => {
    try {
      const options = {
        method: "POST",
        body: JSON.stringify({ item_group: "Products" }),
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await fetch(`${getProducts}`, options);
      if (!response.ok) {
        throw new Error("Failed to fetch venue details");
      }
      const data = await response.json();

      const arr = [];
      const optionList = data.data;
      for (let i = 0; i < optionList?.length; i++) {
        if (optionList[i].price !== 0) {
          arr.push({
            name: optionList[i].name,
            value: optionList[i].name,
            label: optionList[i].name,
            price: optionList[i].price,
            image: optionList[i].image,
            per_minuts_price: optionList[i].per_minuts_price,
            item_category: "Products",
          });
        }
      }
      setConsumableItems(arr);
    } catch (error) {
      console.error("Error fetching venue details:", error);
    }
  };

  const fetchSP002Details = async () => {
    try {
      const options = {
        method: "POST",
        body: JSON.stringify({ item_group: "Services Products 002" }),
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await fetch(`${getProducts}`, options);
      if (!response.ok) {
        throw new Error("Failed to fetch venue details");
      }
      const data = await response.json();

      const arr = [];
      const optionList = data.data;
      for (let i = 0; i < optionList?.length; i++) {
        if (optionList[i].price !== 0) {
          arr.push({
            name: optionList[i].name,
            value: optionList[i].name,
            label: optionList[i].name,
            price: optionList[i].price,
            image: optionList[i].image,
            per_minuts_price: optionList[i].per_minuts_price,
            item_category: "services_products_002",
          });
        }
      }
      setSp00Items(arr);
    } catch (error) {
      console.error("Error fetching venue details:", error);
    }
  };

  const handleSeeMore = () => {
    setShowAll(!showAll);
  };

  useEffect(() => {
    fetchRentalDetails();
    fetchConsumableDetails();
    fetchSP002Details();
  }, []);

  const handleProceedToCheckout = () => {
    navigate("/viewcart");
  };
  const handleBack = () => {
    navigate(-1);
  };
  const handleCartClick = () => {
    navigate("/viewcart");
  };

  return (
    <>
      <div className="max-w-full justify-between items-center py-4 px-6 lg:px-20">
        <header className="mb-4 relative">
          <div className="flex flex-row  justify-between mt-4 mx-4  ">
            <button
              onClick={handleBack}
              className="backBtn flex items-center space-x-2"
            >
              <img
                className="rounded-full nav-profile-pic"
                src={BackButton}
                alt="Back Button"
              />
              <span>Back</span>
            </button>
            <button
              onClick={handleCartClick}
              className="flex  p-2 rounded-full "
            >
              <CartIcon className="w-6 h-6 sm:w-8 sm:h-8 md:w-10 md:h-10 lg:w-12 lg:h-12" />
            </button>
          </div>
          <div className="">
            <div className="flex">
              <h1 className="text-3xl  font-bold text-start">
                {venueData?.data?.venue_name}
              </h1>
              <div className=" px-6">
                <button
                  className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                  onClick={handleProceedToCheckout}
                >
                  Proceed to Checkout
                </button>
              </div>
            </div>
          </div>
        </header>
        <div className="mt-8">
          <h1 className="font-bold text-4xl">Add Accessories</h1>
          <div className="flex justify-between">
            {rentalItems.length > 0 && (
              <h1 className="font-bold text-2xl mb-4">Rental Items</h1>
            )}{" "}
            <div>
              {rentalItems.length > 3 && (
                <>
                  {/* <div className=" ">
                    <button onClick={handleSeeMore} className="text-red-500">
                      {showAll ? "See Less" : "See More"}
                    </button>
                  </div> */}
                </>
              )}
            </div>
          </div>
          <div className="flex flex-wrap justify-center sm:justify-start lg:justify-start mb-10">
            {rentalItems
              .slice(0, showAll ? rentalItems.length : 3)
              .map((item, index) => (
                <AccessoryCard
                  key={index}
                  {...item}
                  nonrental={false}
                  cartItems={cartItems}
                  setCartItems={setCartItems}
                  className="lg:w-1/4 md:w-1/2 sm:w-full"
                />
              ))}
          </div>
          {consumableItems.length > 0 && (
            <h1 className="font-bold text-2xl mb-4">Consumable Items</h1>
          )}{" "}
          <div>
            <div className="flex flex-wrap justify-center sm:justify-start lg:justify-start mb-10">
              {consumableItems
                .slice(0, showAll ? consumableItems.length : 3)
                .map((item, index) => (
                  <AccessoryCard
                    key={index}
                    {...item}
                    nonrental={true}
                    cartItems={cartItems}
                    setCartItems={setCartItems}
                    className="lg:w-1/4 md:w-1/2 sm:w-full"
                  />
                ))}
            </div>
          </div>
          {sp00Items.length > 0 && (
            <h1 className="font-bold text-2xl mb-4">Service Items</h1>
          )}{" "}
          <div>
            <div className="flex flex-wrap justify-center sm:justify-start lg:justify-start mb-10">
              {sp00Items
                .slice(0, showAll ? sp00Items.length : 3)
                .map((item, index) => (
                  <AccessoryCard
                    key={index}
                    {...item}
                    nonrental={true}
                    cartItems={cartItems}
                    setCartItems={setCartItems}
                    className="lg:w-1/4 md:w-1/2 sm:w-full"
                  />
                ))}
            </div>
          </div>
        </div>
        <div className="mt-8 mb-8 flex ">
          <button
            className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
            onClick={handleProceedToCheckout}
          >
            Proceed to Checkout
          </button>
        </div>
      </div>
    </>
  );
};

export default AccessoriesPage;
