import React from 'react';
import { ReactComponent as CartIconSVG } from '../../assets/images/cart.svg'; 

export default function CartIcon() {
  return (
    <div className="flex items-center space-x-2 cursor-pointer">
      <CartIconSVG className="w-6 h-6 text-gray-800" />
      <span className="text-gray-800 font-semibold">View Cart</span>
    </div>
  );
}
