import React, { useEffect, useState } from "react";
import Navbar from "../../component/common/NavbarProfile";
import Success from "../../assets/images/SuccessFull.png";
import { toast } from "react-toastify";
import {
  CheckIfPaymentAlreadyDone,
  Create_si_and_payment_api,
  Create_si_and_payment_api_for_iframe,
  downloadInvoiceHelper,
  handleRedirect,
} from "../../handlers/Payment/requestpayment";
import { useParams } from "react-router-dom";
import { Invoice } from "../../handlers/common/api";

const SuccessfullPage = () => {
  const [showPaymentSuccess, setShowPaymentSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [venueData, setVenueData] = useState([]);
  const [callonce, setcallonce] = useState(true);
  const { platform, id } = useParams();

  let flag = 0;

  useEffect(() => {
    const venue_data = JSON.parse(localStorage.getItem("venueData")) || {};
    setVenueData(venue_data);
  }, []);

  const create_si = async (firstPart, data) => {
    const venue_data = JSON.parse(localStorage.getItem("venueData")) || {};
    setVenueData(venue_data);
    const SI_Payload = {
      si_details: {
        order_number: firstPart,
        booking_for: venue_data?.booking_for,
        venue: venue_data?.data?.name,
        payment_gateway_response: "Success",
        mid_number: data.mid,
        transaction_id: data.transaction_id,
        payer_id: data.payer_id,
        card_first6: data.first_6,
        card_last4: data.last_4,
      },
    };

    try {
      if (callonce) {
        if (platform === "0") {
          const response = await Create_si_and_payment_api(SI_Payload);

          if (response) {
            toast.success("Order has been Created!");
            console.log(response);
            setcallonce(false);
            localStorage.removeItem("cartItems");
            localStorage.removeItem("countdownEndTime");
            localStorage.setItem("SiNo", response?.data?.data?.si_number);
          }
        } else if (platform === "1") {
        } else if (platform === "2") {
          const response = await Create_si_and_payment_api_for_iframe(
            SI_Payload
          );

          if (response) {
            toast.success("Order has been Created!");
            console.log(response);
            setcallonce(false);
            localStorage.removeItem("cartItems");
            localStorage.removeItem("countdownEndTime");
            localStorage.setItem("SiNo", response?.data?.data?.si_number);
          }
        }
      }
    } catch (error) {
      console.log(error);
      toast.error("Failed to create order");
    }
  };

  const fetchTransactionDetails = async () => {
    var transaction_id = "";
    if (typeof window !== "undefined" && window.location.search) {
      var searchParams = new URLSearchParams(window.location.search);
      if (searchParams.has("transaction_id")) {
        transaction_id = searchParams.get("transaction_id");
        let splitValues = transaction_id.split("_");
        let firstPart = splitValues[0];

        const payload = {
          request: {
            transaction_id: transaction_id,
          },
        };
        const res = await handleRedirect(payload);
        if (
          res.data.message.signature_msg ===
          "signature is fine, continue processing the request"
        ) {
          setIsLoading(false);
          setShowPaymentSuccess(true);
          const res2 = await CheckIfPaymentAlreadyDone(firstPart);
          if (res2.data.data.payment_status === 0 && flag === 0) {
            create_si(firstPart, res.data.message.transaction_res);
            flag = 1;
          }
        }
      }
    } else {
      setIsLoading(false);
      return;
    }
  };

  const downloadInvoice = async () => {
    const SiNo = localStorage.getItem("SiNo");

    try {
      if (SiNo) {
        // Convert response to a Blob
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = Invoice + `&name=${SiNo}`;
        a.download = "invoice.pdf"; // Specify the file name for the download
        a.target = "_blank"; // Open in a new tab
        a.rel = "noopener noreferrer"; // Add security attributes
        // Append the anchor to the document body
        document.body.appendChild(a);

        // Trigger a click event on the anchor to start the download
        // a.click();

        // Clean up: remove the anchor element
        document.body.removeChild(a);

        toast.success("Invoice Downloaded!");
        localStorage.removeItem("cartItems");
      }
    } catch (error) {
      console.log(error);
      toast.error("Failed to Download Invoice");
    }
  };

  useEffect(() => {
    fetchTransactionDetails();
  }, []);

  return (
    <>
      {isLoading ? (
        <div className="flex justify-center items-center h-screen">
          <div className="loader"></div>
        </div>
      ) : (
        showPaymentSuccess && (
          <div className="payment-successfull-page">
            <div className="text-center mt-14">
              <h1>Payment Successful</h1>
              <p>Booking done Successfully!</p>
            </div>
            <div className="relative isolate  bg-white">
              <div className="relative  py-24">
                <div className="text-center">
                  <div className="mt-10 flex flex-col items-center justify-center border border-gray-300 absolute inset-0 mx-auto payment-successfull-box">
                    <div className="rounded-full mt-5  bg-[#F6E0E1] flex justify-center items-center">
                      <img
                        src={Success}
                        width={90}
                        height={20}
                        className=""
                        alt="Success"
                      />
                    </div>
                    <h3 className="mt-4"> Done!</h3>
                    <span className="mt-2 mb-8">
                      Booking done Successfully!{" "}
                    </span>
                    <button
                      type="button"
                      className="px-3 py-2 text-sm font-semibold text-white download-invoice"
                      onClick={downloadInvoice}
                    >
                      Download Invoice
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      )}
    </>
  );
};

export default SuccessfullPage;
